import { z } from 'zod';

import { PlayerSchema } from './Player';

export const CardSchema = z.object({
    id: z.string(),
    imageFront: z.string().url(),
    imageBack: z.string().url(),
    grader: z.union([z.literal('psa'), z.literal('bgs'), z.literal('cgc')]).optional(),
    canSelect: z.boolean(),
    flip: z.boolean().optional(),
    rotation: z.number().optional(),
    zoom: z.boolean().optional(),
    player: PlayerSchema.optional(),
});

export type Card = z.infer<typeof CardSchema>;
