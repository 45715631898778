import { z } from 'zod';

import { MESSAGES } from '../constants/messages';
import { CardSchema } from './Card';
import { PlayerSchema } from './Player';

export const MessageSchema = z.object({
    message: MESSAGES,
    currentViewer: z.string(),
    packTextures: z.object({
        image: z.string().url(),
        color: z.string(),
    }),
    items: z.array(z.union([CardSchema, z.literal(false)])),
    maxPlayers: z.number(),
    currentTurn: z.number(),
    players: z.array(PlayerSchema).optional(),
    currentPlayer: z.number(),
    focusedCardIdx: z.number(),
});

export type Message = z.infer<typeof MessageSchema>;

export const SelectedItemMessageSchema = z.object({
    message: z.literal('ITEM_SELECTION'),
    player: PlayerSchema,
    itemIdx: z.number(),
});

export type SelectedItemMessage = z.infer<typeof SelectedItemMessageSchema>;
